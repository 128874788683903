
























import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import { RouterNames } from "@/router/routernames";
import nueva_platoModule from "@/store/modules/visualizacion_dieta/nueva_plato-module";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
  },
})
export default class Platos_lista extends Vue {
  //public ColumnasAlimentos: ClassColumnDataTable[] = [];

  created() {
    nueva_platoModule.getlista_platos();
  }

  public get datasource() {
    return nueva_platoModule.all_nueva_platos;
  }

  public get Columns() {
    var ColumnasAlimentos = [];
    ColumnasAlimentos.push(
      new ClassColumnDataTable(
        "nombre",
        "Nombre",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );
    ColumnasAlimentos.push(
      new ClassColumnDataTable(
        "porcentaje_gr",
        "% Gr.",
        datatypes.number,
        true,
        datatypes.maxlength
      ).ChangeWidth(150)
    );
    ColumnasAlimentos.push(
      new ClassColumnDataTable(
        "porcentaje_glucidos",
        "% Hc",
        datatypes.number,
        true,
        datatypes.maxlength
      ).ChangeWidth(150)
    );
    ColumnasAlimentos.push(
      new ClassColumnDataTable(
        "porcentaje_protein",
        "% Prt.",
        datatypes.number,
        true,
        datatypes.maxlength
      ).ChangeWidth(150)
    );
    ColumnasAlimentos.push(
      new ClassColumnDataTable(
        "sistema",
        "Sistema",
        datatypes.boolean,
        true,
        datatypes.maxlength
      )
    );
    return ColumnasAlimentos;
  }

  public editarDieta(e: number) {
    this.abrir_formulario(e.toString());
  }

  abrir_formulario(id: string) {
    this.$router.push({
      name: RouterNames.platosficha,
      params: { id: id },
    });
  }
}
